.Avatar {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-size: contain;
    background-repeat: no-repeat;
}
