$mainColor: #900312;

.FastTrack {
    border: 4px solid #3BC710;
    box-shadow: 0px 0px 5px 5px rgba(169,248,89,1) !important;
}

.WorkItem {
    max-width: 420px;
    height: 140px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(120,120,120,0.5);
    padding: 15px 20px;
    margin-bottom: 20px;
    border-radius: 15px;

    p {
	margin-block-start: 0;
	margin-block-end: 0;
    }

    .Sketch {
	background-size: cover;
    }

    .WorkItemDetails {
	font-size: 13px;
	color: #4a4a4a;

	p.Sample {
	    text-align: center;
	    margin-bottom: 10px;
	    border-radius: 5px;
	    font-weight: 600;
	    font-size: 20px;
	}

	p.Brand {
	    font-size: 16px;
	    font-weight: 600;
	    text-align: center
	}

	p.SampleType {
	    margin-top: 5px;
	    font-size: 12px;
	    font-weight: 400;
	    text-align: center
	}

	p.UntilDays {
	    margin-top: 5px;
	    font-size: 24px;
	    font-weight: 400;

	}

	p.UntilMessage {
	    font-size: 8px;
	    font-weight: 400;
	    text-transform: uppercase;

	}


	p.Date {
	    margin: 10px 0;
	}

	span.Stylist {
	    background-color: red;
	    font-size: 30px;
	    padding: 5px 10px;
	    border-radius: 20px;
	}

	p.OrderReference {
	    font-size: 12px;
	    padding-top: 5px;
	    font-weight: 300;
	}
    }

    .WorkItemInfo {
	background-color: #fff;
	color: #ccc;

	svg {
	    font-size: 20px !important;
	    margin-left: 5px;
	}

    }

    .Elapsed {
	color: #4a4a4a;
	font-size: 14px;
	font-weight: 500;
    }

}

.Changed {
    border: 4px solid $mainColor;
}

.Outdated {
    color: #ff0000;
}

.Active {
    color: #66cc8f;
}
