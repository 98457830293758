$mainColor: #900312;

.Home {
    background-color: #EFEFEF;
    height: 100vh;

    .CardBoard {
	width: 200px;
	height: 200px;
	margin: 25px;

	a {
	    text-decoration: none;
	}

	h3 {
	    text-transform: uppercase;
	    color: $mainColor;
	    text-decoration: none;
	}
    }
}
