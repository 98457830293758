.WorkList {
    background-color: #F3F5F9;
    height: 85vh;
    box-shadow: 0px 0px 10px 5px rgba(120,120,120,0.5);

    h3 {
	color: #6e6e92;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
	padding-bottom: 10px;
	text-weight: 600;
	font-style: italic;
    }

    .More {
	color: #6e6e92;
    	font-size: 28px;
	width: 460px;
	text-align: right;
    }
}
