.DepartmentDashboard {

    padding-top: 30px;

    img {
	width: 200px;
    }

    .Title {
	font-weight: 400;
	font-size: 26px;
	color: #666666;
    }

    .Worklists {
	background-color: #EEF2F9;
	margin-top: 3vh;
    }
}
